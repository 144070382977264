<template>
<div>
  <b-input v-model="resultId"/>
  <b-alert variant="danger" :show="errorMsg != null" @click="errorMsg = null" dismissible>
    Error: {{ errorMsg }}<br/>
  </b-alert>  
  <Survey :survey="survey" v-if="survey"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("survey");
import { db } from "@/services/db";
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-vue-ui';
import { loadObj } from '@/services/dbutils';

StylesManager.applyTheme("defaultV2");

const surveyJson = {
  elements: [{
    name: "FirstName",
    title: "Enter your first name:",
    type: "text"
  }, {
    name: "LastName",
    title: "Enter your last name:",
    type: "text"
  }, {
    "type": "ranking",
    "name": "smartphone-features",
    "title": "Please rank the following smartphone features in order of importance:",
    "choices": [
      "Battery life",
      "Screen size",
      "Storage space",
      "Camera quality",
      "Durability",
      "Processor power",
      "Price"
    ]
  }]
};

export default {
  name: 'MyFirstSurvey',
  components: {
    Survey
  },
  data() {
    return {
      resultId: "result-01",
      survey: null,
      errorMsg: null,
    }
  },
  async mounted() {
    let surveyId = this.$route.params.surveyid;
    if (surveyId) {
      log.log("Loading", surveyId);
      this.survey = new Model(await loadObj(db.collection("LiveSurveys").doc(surveyId)));
    }
    else {
      log.log("No survey selected");
      this.survey = new Model(surveyJson);
    }
    this.survey.onComplete.add(this.saveResults);
  },
  methods: {
    async saveResults(sender) {
      let surveyId = this.$route.params.surveyid;
      log.log("results", sender.data);
      if (!surveyId)
        return;
      try {
        await db.collection(`LiveSurveys/${surveyId}/results`).doc(this.resultId).set(sender.data);
      } catch (e) {
        this.errorMsg = "Couldn't save results. " + e.message;
        log.error(e.message);
      }
    }
  },
}
</script>